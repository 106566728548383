import React, { useContext, useEffect, useState } from 'react';

import { UserAdditionalFieldsDto } from '@arkadium/eagle-user-client/dist/types/api/v1/dto/user-additional-fields.dto';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';


import styles from './AvatarsPanel.css';
import { FramesPanel } from './FramesPanel/FramesPanel';
import { ColorUtils } from '../../../../../utils/ColorUtils';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { NavLink } from '../../../../atoms/Link/Link';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { AvatarImagesData, getAvatarAnalyticsKey } from '../../../../constants/AvatarImagesData';
import { HeaderSideMenuTabs } from '../../../../constants/HeaderSideMenuTabs';
import { ProfileRightSideRoutes } from '../../../../constants/Pages';
import { SignInUpComponents } from '../../../../constants/SignInUpComponents';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import { SubscriptionSource } from '../../../../models/Subscription/SubscriptionData';
import { RegistrationSource } from '../../../../models/User/RegistrationSource';
import { UserModel } from '../../../../models/User/UserModel';
import CaptchaIframe from '../../../../molecules/Captcha/CaptchaIframe';
import useCaptcha, { RECAPTCHA_ACTIONS, RECAPTCHA_MODES } from '../../../../molecules/Captcha/hooks/useCaptcha';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LEANPLUM_EVENTS, LeanplumAnalytics } from '../../../../services/Analytics/LeanplumAnalytics';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { GameService } from '../../../../services/GameService';
import { LocalStorageService } from '../../../../services/LocalStorage';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';
import UserService, { AuthType } from '../../../../services/UserService';
import {
  setProfilePanelState,
  setSideMenuActivePage,
  setSignInUpState,
  setSnackbarData
} from '../../../../store/ducks/layout';
import { setShowRecaptcha } from '../../../../store/ducks/recaptcha';
import { setSubscriptionSource } from '../../../../store/ducks/subscription/common';
import { UserEffects } from '../../../../store/effects/user.effects';
import { AvatarsList } from '../../../AvatarsList/AvatarsList';

type AvatarsPanelProps = {
  user: UserModel;
  activeState: ProfileRightSideRoutes;
  saveUser: (user: any) => void;
  closePanel: () => void;
};

export const AvatarsPanel = React.memo(({ user, saveUser, activeState, closePanel }: AvatarsPanelProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authDataContext = useContext(AuthDataContext);
  const { email, password, checkBox, authProvider, authProviderToken } = authDataContext.value;
  const [selectedFilename, setSelectedFilename] = useState<string>(user?.avatar || '');
  const [isCustomizePanel, setIsCustomizePanel] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserAdditionalFieldsDto>(null);
  const userCustomizedBackground = useSelector(({ framesPanel }) => framesPanel?.background || '');
  const userCustomizedFrame = useSelector(({ framesPanel }) => framesPanel?.frame || '');
  const opensModalElementRef = useSelector(({ opensModalElementRef }) => opensModalElementRef);
  const activeSignInUpState = useSelector(({ signInUpState }) => signInUpState.activeComponent);
  const categoryPageName = useSelector(({ categoryPageName }) => categoryPageName);
  const gameArkadiumSlug = useSelector(({ gameArkadiumSlug }) => gameArkadiumSlug);
  const games = useSelector(({ games }) => games);
  const socialRegistrationLocation = useSelector(({ socialRegistrationLocation }) => socialRegistrationLocation);
  const authProviderFromLocalStorage = LocalStorageService.getItem('authProvider');
  const {
    iframeRef,
    iframeURL,
    setIsIframeCaptchaLoaded,
    getCaptchaToken,
    clearCaptchaData,
    showChallengeRecaptcha,
    captchaToken
  } = useCaptcha();
  const game = gameArkadiumSlug
    ? GameService.findGameByArkadiumSlug(GameService.gameModelToGame(games), gameArkadiumSlug)
    : '';

  useEffect(() => {
    const isCustomizePanelOpened = activeState === ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL;

    setIsCustomizePanel(isCustomizePanelOpened);
    setIsSignUp(activeSignInUpState === SignInUpComponents.PERSONALIZE_PROFILE);
  }, [activeState]);

  useEffect(() => {
    return () => {
      opensModalElementRef?.focus();
    };
  }, []);

  useEffect(() => {
    if (captchaToken) {
      if (authProvider === AuthType.Facebook) {
        void fetchLoginViaFacebook(userData);
      } else if (authProvider === AuthType.Google) {
        void fetchLoginViaGoogle(userData);
      } else {
        void fetchUserRegister(userData);
      }
    }
  }, [captchaToken]);

  const handleOpenSubscription = () => {
    UserService.closePanel();
    dispatch(setSubscriptionSource(SubscriptionSource.AVATAR_PANEL));
    UserService.openSubscriptionPanel();
  };
  const fetchLoginViaFacebook = async (data: UserAdditionalFieldsDto) => {
    try {
      await UserService.loginViaFacebook(
        {
          token: authProviderToken,
          email,
          emailRetrievalConsent: checkBox,
          registrationPlaceUrl: new URL(UserService.generateUrl()),
          user: data,
          password: null,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
        }
      );
      void Analytics.trackEvent(Analytics.profile.socialEmailRequest());
      handleLoginFacebookSuccess();
    } catch (err) {
      handleLoginFacebookError(err);
    }
  };
  const handleLoginFacebookSuccess = () => {
    clearCaptchaData();
    setIsLoading(false);
    LocalStorageService.setItem('authProvider', AuthType.Facebook);
    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
    UserService.openAfterSignPanel();
  };
  const handleLoginFacebookError = ({ errorCode }) => {
    //UserNotConfirmedError
    if (errorCode === 1018) {
      authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Facebook });
      dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
    } else {
      dispatch(setShowRecaptcha(errorCode === 1023));
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(errorCode),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const fetchLoginViaGoogle = async (data: UserAdditionalFieldsDto) => {
    try {
      await UserService.loginViaGoogle(
        {
          token: authProviderToken,
          email,
          emailRetrievalConsent: checkBox,
          registrationPlaceUrl: new URL(UserService.generateUrl()),
          password: null,
          user: data,
          captchaToken,
          captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
        }
      );
      void Analytics.trackEvent(Analytics.profile.socialEmailRequest());
      handleLoginViaGoogleSuccess();
    } catch (err) {
      handleLoginViaGoogleError(err);
    }
  };
  const handleLoginViaGoogleError = ({ errorCode }) => {
    if (errorCode === 1018) {
      authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
      dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
    } else {
      dispatch(setShowRecaptcha(errorCode === 1023));
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(errorCode),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const handleLoginViaGoogleSuccess = () => {
    clearCaptchaData();
    setIsLoading(false);
    LocalStorageService.setItem('authProvider', AuthType.Google);
    authDataContext.setAuthData({ ...authDataContext.value, email, authProvider: AuthType.Google });
    UserService.openAfterSignPanel();
  };
  const handleSocialRequest = (data: UserAdditionalFieldsDto) => {
    if (authProvider === AuthType.Facebook || authProvider === AuthType.Google) {
      setUserData(data);
      getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_IN);
    } else {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: 'No social network selected',
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const fetchUserUpdate = async (data: UserAdditionalFieldsDto) => {
    try {
      await UserService.userUpdate(data);
      await UserService.userLoad();
      UserService.closePanel();
    } catch (err) {
      dispatch(
        setSnackbarData({
          isOpened: true,
          message: UserService.errorCodeToText(err),
          type: 'error',
          parentNode: 'rightSideMenu'
        })
      );
    }
  };
  const fetchUserRegister = async (data: UserAdditionalFieldsDto) => {
    try {
      const result = await UserService.userRegister({
        email,
        password,
        emailRetrievalConsent: checkBox,
        registrationPlaceUrl: new URL(UserService.generateUrl()),
        user: data,
        captchaToken,
        captchaMode: showChallengeRecaptcha ? RECAPTCHA_MODES.CHALLENGE : undefined
      });

      handleUserRegisterSuccess(result);
    } catch (err) {
      handleUserRegisterError(err);
    }
  };
  const handleUserRegisterSuccess = (uid: string) => {
    clearCaptchaData();
    LocalStorageService.setItem('authProvider', AuthType.EmailPassword);
    const avatarAnalyticsKey = getAvatarAnalyticsKey(selectedFilename);

    LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.REGISTRATION);
    // To have LP userAttribute {registered: true} set here we don't have email confirmed,
    // final Eagle user id, and LP session started with this id to set it in LP to correct user

    void Analytics.trackEvent(
      Analytics.profile.registration(
        categoryPageName,
        game,
        RegistrationSource.EMAIL,
        avatarAnalyticsKey,
        userCustomizedBackground,
        socialRegistrationLocation,
        uid
      )
    );
    batch(() => {
      dispatch(
        setProfilePanelState({
          isOpened: false,
          caption: '',
          target: ProfileRightSideRoutes.NOOP
        })
      );
      dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
      dispatch(setSignInUpState(SignInUpComponents.AFTER_SIGN));
    });
  };
  const handleUserRegisterError = (err: number) => {
    setIsLoading(false);
    dispatch(setShowRecaptcha(err === 1023));
    dispatch(
      setSnackbarData({
        isOpened: true,
        message: UserService.errorCodeToText(err),
        type: 'error',
        parentNode: 'rightSideMenu'
      })
    );
  };
  const handleClickNextBtn = () => {
    const isPremiumFilename = selectedFilename?.includes('premium');

    setIsLoading(true);

    // for user registration
    if (isSignUp) {
      const data = {
        name: authDataContext.value.name,
        avatar: selectedFilename,
        avatarBackground: userCustomizedBackground
      };

      if (user) {
        return fetchUserUpdate(data);
      } else if (authProvider && authProvider !== AuthType.EmailPassword) {
        // flow: add an email to the user if he registered through a social network through a phone number
        // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=583%3A13950
        // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517611608158&cot=14
        return handleSocialRequest(data);
      } else {
        // flow: register user via email
        // figma: https://www.figma.com/file/3ULngpxCaEdP2yeCUHYEm8/Smarter-Login-%3E-DEV?node-id=580%3A13847
        // miroboard: https://miro.com/app/board/uXjVOU8ivQY=/?moveToWidget=3458764517115541698&cot=14
        setUserData(data);
        return getCaptchaToken(RECAPTCHA_ACTIONS.SIGN_UP);
      }
    }

    if (!isCustomizePanel) {
      // FOR Select panel
      saveUser({
        ...{
          avatar: selectedFilename,
          subscriberAvatar: {
            avatar: isPremiumFilename ? selectedFilename : null,
            frame: user?.subscriberAvatar?.frame
          }
        },
        ...ProfileProcessService.getStepForUpdate(12) // this callback also sends a request to BackEnd -> save user
      });
      dispatch(
        setProfilePanelState({
          isOpened: true,
          caption: '',
          target: ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL
        })
      );
      setIsLoading(false);
      const avatarAnalyticsKey = AvatarImagesData.find(
        (item) => item.filename === selectedFilename
      )?.analyticsKey;

      void Analytics.trackEvent(
        Analytics.profile.profileAvatar(
          avatarAnalyticsKey || '',
          MiscUtils.getAuthSource(authProviderFromLocalStorage)
        )
      );
      // openWeb hack to update user on openWeb load
      LocalStorageService.removeItem('SPOTIM_ACCESS_TOKEN', false);
    } else {
      // FOR Customize panel
      // SAVe user to BD request
      dispatch(
        UserEffects.updateUser({
          avatarBackground: userCustomizedBackground || '',
          subscriberAvatar: {
            frame: userCustomizedFrame
          }
        })
      );
      setIsLoading(false);
      const avatarBackgroundColor = ColorUtils.getBackgroundColorName(userCustomizedBackground);

      void Analytics.trackEvent(
        Analytics.profile.profileAvatarCustomize(
          avatarBackgroundColor,
          userCustomizedFrame,
          MiscUtils.getAuthSource(authProviderFromLocalStorage)
        )
      );
      dispatch(
        setProfilePanelState({
          isOpened: false,
          caption: '',
          target: ProfileRightSideRoutes.NOOP
        })
      );
    }
  };
  const getNextBtnLabel = () => {
    if (isSignUp) {
      return t('AVATARS_PANEL.BTN_LABEL_SAVE_AND_FINISH');
    }

    if (isCustomizePanel) {
      return t('AVATARS_PANEL.BTN_LABEL_SAVE');
    }

    return t('AVATARS_PANEL.BTN_LABEL_NEXT');
  };
  const getBody = () => {
    if (isCustomizePanel) {
      return <FramesPanel isSignUp={isSignUp}/>;
    }

    return (
      <AvatarsList
        isSignUp={isSignUp}
        selectedAvatar={selectedFilename}
        onChange={(v) => {
          setSelectedFilename(v);
        }}
      />
    );
  };
  const getFirsSentence = () => {
    if (isCustomizePanel) {
      return t('AVATARS_PANEL.CUSTOMIZE_AVATAR');
    }

    return t('AVATARS_PANEL.SELECT_AVATAR');
  };
  const handleClose = () => {
    if (activeSignInUpState === SignInUpComponents.PERSONALIZE_PROFILE) {
      batch(() => {
        dispatch(
          setProfilePanelState({
            isOpened: false,
            caption: '',
            target: ProfileRightSideRoutes.NOOP
          })
        );
        dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
        dispatch(setSignInUpState(SignInUpComponents.PERSONALIZE_PROFILE));
      });
    } else {
      clearCaptchaData();
      closePanel();
    }
  };

  return (
    <>
      {!showChallengeRecaptcha && (
        <div
          className={styles.content}
          role="tabpanel"
          aria-labelledby={
            isCustomizePanel ? ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL : ProfileRightSideRoutes.AVATARS_PANEL
          }
        >
          {!isSignUp && (
            <>
              {!UserService.isUserSubscriber() && (
                <div className={styles.info}>
                  <strong>{getFirsSentence()}&nbsp;</strong>
                  {t('AVATARS_PANEL.ACCESS_FULL_SELECTION')}&nbsp;
                  <NavLink onClick={handleOpenSubscription}>{t('AVATARS_PANEL.ARKADIUM_ADVANTAGE')}</NavLink>
                </div>
              )}
            </>
          )}
          {getBody()}
          <Responsive>
            <MobilePanelFooter twoColumns>
              <Button outlined onClick={handleClose} className={classNames(styles.button, styles.btnClose)}>
                {t('AVATARS_PANEL.CANCEL_BTN')}
              </Button>
              <Button
                onClick={handleClickNextBtn}
                loading={isLoading}
                className={classNames(styles.button, isSignUp && styles.btnSignUp)}
              >
                {getNextBtnLabel()}
              </Button>
            </MobilePanelFooter>
          </Responsive>
        </div>
      )}
      <div className={styles.captcha}>
        <CaptchaIframe iframeRef={iframeRef} iframeURL={iframeURL} setIsIframeCaptchaLoaded={setIsIframeCaptchaLoaded}/>
      </div>
    </>
  );
});
