import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const TickIcon = React.memo((props: iconProps) => {
    return (
        <svg
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
            data-testid="tick-icon"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M0 0H34V34H0z" transform="translate(-266 -85) translate(266 85)" />
                <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 15.873L16.325 21 23 13"
                    transform="translate(-266 -85) translate(266 85)"
                />
            </g>
        </svg>
    );
});
