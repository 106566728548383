import React, { useState } from 'react';

import loadable from '@loadable/component';
import { Country, isValidPhoneNumber } from 'react-phone-number-input/input';

import { MiscUtils } from '../../../../../utils/MiscUtils';
import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { UserModel } from '../../../../models/User/UserModel';
import styles from './PhoneNumberPanel.css';

const PhoneNumberInput = loadable(() =>
    MiscUtils.loadableRetry(() => import('../../../../molecules/PhoneNumberInput/PhoneNumberInput'), { retries: 3 })
);

type Props = {
    saveUser: (user: any) => void;
    closePanel: () => void;
    user: UserModel;
};

export const PhoneNumberPanel = React.memo((props: Props) => {
    const [value, setValue] = useState(props.user.phone || '');
    const [isFocused, setIsFocused] = useState(false);
    const [validNumber, setValidNumber] = useState(false);

    return (
        <div className={styles.content}>
            <PhoneNumberInput
                value={value}
                countryCode={props.user.countryId as Country}
                onChange={(v) => {
                    setValue(v);
                    if (v) {
                        setValidNumber(isValidPhoneNumber(v) === true);
                    }
                }}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                onSave={() => {
                    props.saveUser({
                        phone: value || '',
                    });
                    props.closePanel();
                }}
                isValid={validNumber}
            />
            <Responsive maxWidth={1024}>
                <MobilePanelFooter>
                    <BorderedButton
                        filled
                        caption="Save"
                        disabled={(value || '').trim().length === 0 || !validNumber}
                        onClick={() => {
                            props.saveUser({
                                phone: value || '',
                            });
                            props.closePanel();
                        }}
                    />
                </MobilePanelFooter>
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <MobilePanelFooter>
                    <BorderedButton caption="Cancel" onClick={props.closePanel} />

                    <BorderedButton
                        filled
                        caption="Save"
                        disabled={(value || '').trim().length === 0 || !validNumber}
                        onClick={() => {
                            props.saveUser({
                                phone: value || '',
                            });
                            props.closePanel();
                        }}
                    />
                </MobilePanelFooter>
            </Responsive>
        </div>
    );
});
