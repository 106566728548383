import React, { MemoExoticComponent, useEffect, useState } from 'react';

import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { AvatarsPanel } from './Panels/AvatarsPanel/AvatarsPanel';
import { BirthdayPanel } from './Panels/BirthdayPanel/BirthdayPanel';
import { CountryPickerPanel } from './Panels/CountryPickerPanel/CountryPickerPanel';
import { EmailPanel } from './Panels/EmailPanel/EmailPanel';
import { GenderPanel } from './Panels/GenderPanel/GenderPanel';
import { MyLanguagePanel } from './Panels/MyLanguagePanel/MyLanguagePanel';
import { AvatarNavigationTabs } from './Panels/NavigationTabs/AvatarNavigationTabs';
import { PhoneNumberPanel } from './Panels/PhoneNumberPanel/PhoneNumberPanel';
import { UserNamePanel } from './Panels/UserNamePanel/UserNamePanel';
import styles from './RightSlideInPanel.css';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { ArrowRightIcon } from '../../atoms/Icons/Styleguide/ArrowRightIcon';
import { CloseIcon } from '../../atoms/Icons/Styleguide/CloseIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { ProfileRightSideRoutes } from '../../constants/Pages';
import { UserModel } from '../../models/User/UserModel';
import { TabRouter } from '../../molecules/TabRouter/TabRouter';
import { setProfilePanelState } from '../../store/ducks/layout';
import { UserEffects } from '../../store/effects/user.effects';

type TProps = {
  field: string,
  hint: string,
  validation: string
}
const routes = new Map<ProfileRightSideRoutes, React.ElementType>();
const WithFieldMeta =
  (Component: MemoExoticComponent<(props: TProps) => any>) =>
    ({ field, hint, validation }) =>
      (props: TProps) => {
        const newProps = {
          ...props,
          field,
          hint,
          validation
        };

        return <Component {...newProps} />;
      };
const FirstNamePanel = WithFieldMeta(UserNamePanel)({
  field: 'firstName',
  hint: 'Enter your first name',
  validation: 'firstName'
});
const LastNamePanel = WithFieldMeta(UserNamePanel)({
  field: 'lastName',
  hint: 'Enter your last name',
  validation: 'lastName'
});
const ScreenNamePanel = WithFieldMeta(UserNamePanel)({
  field: 'name',
  hint: 'Enter your screen name',
  validation: 'screenName'
});

routes.set(ProfileRightSideRoutes.NOOP, () => null);
routes.set(ProfileRightSideRoutes.AVATARS_PANEL, AvatarsPanel);
routes.set(ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL, AvatarsPanel);
routes.set(ProfileRightSideRoutes.SCREEN_NAME_PANEL, ScreenNamePanel);
routes.set(ProfileRightSideRoutes.FIRST_NAME_PANEL, FirstNamePanel);
routes.set(ProfileRightSideRoutes.LAST_NAME_PANEL, LastNamePanel);
routes.set(ProfileRightSideRoutes.COUNTRY_PICKER_PANEL, CountryPickerPanel);
routes.set(ProfileRightSideRoutes.MY_LANGUAGE_PANEL, MyLanguagePanel);
routes.set(ProfileRightSideRoutes.BIRTHDAY_PANEL, BirthdayPanel);
routes.set(ProfileRightSideRoutes.PHONE_NUMBER_PANEL, PhoneNumberPanel);
routes.set(ProfileRightSideRoutes.GENDER_PANEL, GenderPanel);
routes.set(ProfileRightSideRoutes.EMAIL_PANEL, EmailPanel);

const ANIMATION_DURATION = 600;
const RightSlideInPanel = () => {
  const dispatch = useDispatch();
  const { isOpened, caption, targetPanel, user } = useSelector(({ profilePanel, user }) => ({
    isOpened: profilePanel.isOpened,
    caption: profilePanel.caption,
    targetPanel: profilePanel.target,
    user
  }));
  const [isFocusTrapActive, setIsFocusTrapActive] = useState<boolean>(false);

  useEffect(() => {
    const mediaQueryWatcher = window.matchMedia(`(min-width:${ArkCssBreakpoints.ARK_SMALL_DESKTOP}px)`);

    setIsFocusTrapActive(mediaQueryWatcher.matches);

    const handleMediaQuery = (event: MediaQueryListEvent) => {
      setIsFocusTrapActive(event.matches);
    };

    mediaQueryWatcher.addEventListener('change', handleMediaQuery);

    return () => {
      mediaQueryWatcher.removeEventListener('change', handleMediaQuery);
      setIsFocusTrapActive(false);
    };
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
      document.body.style.overflowY = 'visible';
    }
  }, [isOpened]);

  const saveUser = (updatedUser: UserModel) => {
    dispatch(UserEffects.updateUser({ ...updatedUser }));
  };
  const closePanel = () => {
    dispatch(
      setProfilePanelState({
        isOpened: false,
        caption: caption,
        target: targetPanel
      })
    );
  };
  const handleOpenTab = (targetPanelValue: ProfileRightSideRoutes) => {
    dispatch(
      setProfilePanelState({
        isOpened: true,
        caption: '',
        target: targetPanelValue
      })
    );
  };
  const isAvatarPanels = () => {
    return (
      targetPanel === ProfileRightSideRoutes.AVATARS_PANEL ||
      targetPanel === ProfileRightSideRoutes.AVATAR_CUSTOMIZE_PANEL
    );
  };
  const checkWhenFocusTrap = (): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(resolve, ANIMATION_DURATION);
    });
  };
  const focusTrapOptions = {
    initialFocus: `[data-element-description=side-menu-hide]`,
    checkCanFocusTrap: checkWhenFocusTrap,
    allowOutsideClick: true
  };

  return (
    <CSSTransition
      in={isOpened}
      timeout={ANIMATION_DURATION}
      classNames={{
        enter: styles.myNodeEnter,
        enterActive: styles.myNodeEnterActive,
        exit: styles.myNodeExit,
        exitActive: styles.myNodeExitActive,
        exitDone: styles.myNodeExitDone
      }}
      unmountOnExit
    >
      <FocusTrap active={isFocusTrapActive} focusTrapOptions={focusTrapOptions}>
        <div
          className={classNames(styles.panel, { [styles.leveled]: isOpened })}
          role="dialog"
          aria-modal="true"
          aria-label="avatar settings"
        >
          <div
            className={classNames(styles.header, { [styles.avatarsPanel]: isAvatarPanels() })}
          >
            <div
              className={classNames(
                styles.panelCaption,
                styles[targetPanel],
                { [styles.avatarsPanel]: isAvatarPanels() }
              )}
            >
              {isAvatarPanels() ? (
                <AvatarNavigationTabs activeTab={targetPanel} handleClick={handleOpenTab}/>
              ) : (
                <ArkadiumFullLogo textHoverColor="#dc1e34" arrowHoverColor="#dc1e34"/>
              )}
            </div>
            <div>
              <Responsive maxWidth={1024}>
                <button className={styles.closeBtn} onClick={closePanel}>
                  <CloseIcon/>
                </button>
              </Responsive>
              <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <button
                  className={styles.navButton}
                  onClick={closePanel}
                  data-element-description="side-menu-hide"
                >
                  <ArrowRightIcon className={styles.navButtonIcon}/>
                  <I18nText keyName="SIDEBAR_HIDE" className={styles.iconCaption}/>
                </button>
              </Responsive>
            </div>
          </div>
          <div className={styles.content}>
            <TabRouter
              activeState={targetPanel}
              componentsMap={routes}
              saveUser={saveUser}
              user={user}
              closePanel={closePanel}
              dispatch={dispatch}
            />
          </div>
        </div>
      </FocusTrap>
    </CSSTransition>
  );
};

export default RightSlideInPanel;
