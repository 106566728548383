import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const SearchSmallIcon = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="search-small-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g fill="none" fillRule="evenodd">
                <g>
                    <path d="M0 0H24V24H0z" />
                    <path
                        stroke="#AAA"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14.824 15.53c3.508 0 6.352-2.845 6.352-6.354 0-3.508-2.844-6.352-6.352-6.352-3.51 0-6.353 2.844-6.353 6.352 0 3.51 2.844 6.353 6.353 6.353zm-4.942-1.412L3.53 20.47"
                    />
                </g>
            </g>
        </svg>
    );
});
