import React from 'react';

import { iconProps } from '../../../models/Icon/IconPropsModel';

export const IconShow = React.memo((props: iconProps) => {
    return (
        <svg
            role="img"
            aria-label="show-icon"
            className={props.className}
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="34"
            viewBox="0 0 34 34"
        >
            <g fill="none" fillRule="evenodd">
                <g fillRule="nonzero" stroke="currentColor" strokeWidth="2">
                    <g>
                        <path
                            d="M16.679 21.017c-1.084 0-2.026-.396-2.828-1.188-.803-.792-1.204-1.74-1.204-2.844 0-1.104.391-2.046 1.172-2.828.782-.781 1.724-1.172 2.828-1.172 1.105 0 2.047.391 2.829 1.172.78.782 1.171 1.724 1.171 2.828 0 1.105-.385 2.052-1.156 2.844-.77.792-1.708 1.188-2.812 1.188z"
                            transform="translate(-246 -7) translate(246 7)"
                        />
                        <path
                            strokeLinecap="round"
                            d="M5 17.011c2.596 5.34 6.477 8.011 11.642 8.011 5.166 0 9.05-2.67 11.653-8.01"
                            transform="translate(-246 -7) translate(246 7)"
                        />
                        <path
                            strokeLinecap="round"
                            d="M5 9c2.596 5.34 6.477 8.011 11.642 8.011 5.166 0 9.05-2.67 11.653-8.011"
                            transform="translate(-246 -7) translate(246 7) matrix(1 0 0 -1 0 26.011)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
});
