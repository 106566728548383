import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { TranslationService } from '../../services/TranslationService';
import { CloseIcon } from '../Icons/Styleguide/CloseIcon';
import { IconShow } from '../Icons/Styleguide/IconShow';
import { WarningIcon } from '../Icons/Styleguide/WarningIcon';
import styles from './Input.css';

type ValidationModuleType = typeof import('./Input.validation');

type Props = {
    value: string;
    id?: string;
    autoComplete?: string;
    onValueChange?: (v: string) => void;
    onChange?: (e: any) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    placeholder?: string; //TODO: check this
    maxlength?: number;
    className?: string;
    errorMessage?: string;
    isValid?: boolean;
    setIsValid?: (e: boolean) => void;
    name?: string;
    type?: string;
    warningIconDisabled?: boolean;
    errorMessageOverride?: string;
    isProfanity?: boolean;
    isMessageVisible?: (v: boolean) => void;
    enterPressed?: () => void;
    ariaDescribedBy?: string;
};

export const Input = React.memo(
    React.forwardRef<HTMLInputElement, Props>((props, ref) => {
        const {
            value,
            autoComplete = 'new-password',
            onValueChange,
            onChange,
            onFocus,
            onBlur,
            placeholder,
            maxlength,
            className,
            errorMessage = '',
            isValid,
            setIsValid,
            type: typeOfInputFromProps,
            name,
            warningIconDisabled,
            errorMessageOverride,
            isProfanity,
            isMessageVisible,
            enterPressed,
            id,
            ariaDescribedBy,
        } = props;

        const [inputErrorMessage, setInputErrorMessage] = useState(errorMessage);
        const [isFocused, setIsFocused] = useState(false);
        const [handleValidation, setHandleValidation] = useState<ValidationModuleType | null>(null);
        const [type, setType] = useState(typeOfInputFromProps || 'text');
        const ERROR_MESSAGE_ELEMENT_ID = `${type}-error-message`;

        useEffect(() => {
            import('./Input.validation').then((handleValidation) => {
                setHandleValidation(handleValidation);
            });
        }, []);

        useEffect(() => {
            if (name && typeof isValid === 'boolean' && handleValidation) {
                const validationSchema = handleValidation.handleValidation(name);
                validationSchema
                    .isValid({
                        name: value,
                    })
                    .then((valid) => {
                        setIsValid?.(valid);
                    });

                validationSchema.validate({ name: value }).catch((err) => {
                    setIsValid?.(false);
                    setInputErrorMessage(err.errors[0]);
                    isMessageVisible?.(Boolean(err.errors[0]));
                });
            }
        }, [name, value]);

        useEffect(() => {
            if (isProfanity) {
                setIsValid?.(false);
                setInputErrorMessage(TranslationService.translateIt('SORRY_THAT_SCREEN_NAME_IS_NOT_AVAILABLE'));
            }
        }, [isProfanity]);

        const handleClearIconClick = () => {
            onValueChange?.('');
            onChange?.('');
            setIsValid?.(false);
        };

        const handleEnter = (e) => {
            if (e.keyCode === 13) {
                enterPressed?.();
            }
        };

        const getAriaDescribedByAttr = (): string => {
            let attributeValue = '';

            if (inputErrorMessage || errorMessageOverride) {
                attributeValue += ' ' + ERROR_MESSAGE_ELEMENT_ID;
            }

            if (ariaDescribedBy) {
                attributeValue += ' ' + ariaDescribedBy;
            }

            return attributeValue.trim();
        };

        return (
            <div className={styles.root}>
                <div className={styles.inputWrapper}>
                    <input
                        id={id}
                        type={type}
                        name={name}
                        ref={ref}
                        value={value}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                        className={classNames(
                            styles.inputField,
                            className,
                            value.length > 0 && isValid && styles.filled,
                            typeof isValid === 'boolean' && !isValid && styles.invalidInput
                        )}
                        maxLength={maxlength}
                        aria-describedby={getAriaDescribedByAttr() || undefined}
                        onFocus={() => {
                            onFocus?.();
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            onBlur?.();
                        }}
                        onChange={(e) => {
                            onValueChange?.(e.target.value);
                            onChange?.(e);
                        }}
                        onKeyDown={(e) => handleEnter(e)}
                    />
                    {value.length > 0 && isFocused && typeOfInputFromProps !== 'password' && (
                        <button
                            className={classNames(
                                styles.clearIcon,
                                name?.includes('search') && styles.searchInputClearIcon
                            )}
                            onClick={() => {
                                handleClearIconClick();
                                setIsFocused(false);
                            }}
                            aria-label="clear input value"
                        >
                            <CloseIcon />
                        </button>
                    )}
                    {typeOfInputFromProps === 'password' && isFocused && (
                        <button
                            className={classNames(styles.showIcon)}
                            onClick={() => {
                                type === 'password' ? setType('text') : setType('password');
                            }}
                            aria-label={type === 'password' ? 'show password' : 'hide password'}
                        >
                            <IconShow />
                        </button>
                    )}
                    {!isValid && typeof isValid === 'boolean' && !warningIconDisabled && (
                        <WarningIcon className={styles.warningIcon} />
                    )}
                </div>
                {!isValid && typeof isValid === 'boolean' && !errorMessageOverride && (
                    <p id={ERROR_MESSAGE_ELEMENT_ID} className={styles.errorMessage}>
                        {inputErrorMessage}
                    </p>
                )}
                {errorMessageOverride && (
                    <p id={ERROR_MESSAGE_ELEMENT_ID} className={styles.errorMessage}>
                        {errorMessageOverride}
                    </p>
                )}
            </div>
        );
    })
);
Input.displayName = 'InputFiled';
