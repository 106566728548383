export const AvatarBackgroundColors = [
    { name: 'orange', color: 'rgba(232, 126, 28, 1)' },
    { name: 'neon_red', color: 'rgba(251, 125, 97, 1)' },
    { name: 'yellow', color: 'rgba(255, 193, 7, 1)' },
    { name: 'grass', color: 'rgba(186, 222, 41, 1)' },
    { name: 'emerald', color: 'rgba(38, 141, 124, 1)' },
    { name: 'dull_green', color: 'rgba(79, 163, 149, 1)' },
    { name: 'sea_green', color: 'rgba(62, 195, 165, 1)' },
    { name: 'blue', color: 'rgba(38, 86, 173, 1)' },
    { name: 'sky', color: 'rgba(133, 171, 235, 1)' },
    { name: 'indigo', color: 'rgba(98, 50, 183, 1)' },
    { name: 'purple', color: 'rgba(158, 85, 213, 1)' },
    { name: 'lavender', color: 'rgba(206, 161, 240, 1)' },
    { name: 'black', color: 'rgba(54, 54, 54, 1)' },
    { name: 'white', color: 'rgba(239, 239, 239, 1)' },
];
