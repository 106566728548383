import React, { useContext, useState } from 'react';

import classNames from 'classnames';

import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { ButtonWhileTyping } from '../../../../atoms/Buttons/ButtonWhileTyping/ButtonWhileTyping';
import { Input } from '../../../../atoms/Input/Input';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { UserModel } from '../../../../models/User/UserModel';
import { Analytics } from '../../../../services/Analytics/Analytics';
import styles from './EmailPanel.css';
import { AuthDataContext } from '../../../../services/AuthDataReact';
import { MiscUtils } from '../../../../../utils/MiscUtils';

type Props = {
    saveUser: (user: any) => void;
    closePanel: () => void;
    user: UserModel;
};

export const EmailPanel = React.memo((props: Props) => {
    const [value, setValue] = useState(props.user.email || '');
    const [focus, setFocus] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const authDataContext = useContext(AuthDataContext);
    const { authProvider } = authDataContext.value;

    return (
        <div className={styles.content}>
            <div
                className={classNames(styles.inputContainer, {
                    [styles.inputContainerFocused]: focus,
                })}
            >
                <Input
                    value={value}
                    onValueChange={setValue}
                    onFocus={() => setFocus(true)}
                    onBlur={() =>
                        setTimeout(() => {
                            setFocus(false);
                        }, 200)
                    }
                    isValid={isValid}
                    setIsValid={setIsValid}
                    name="email"
                />
                <ButtonWhileTyping
                    isValid={isValid}
                    focus={focus}
                    onClick={() => {
                        if (isValid) {
                            props.saveUser({
                                email: value,
                            });
                            props.closePanel();
                        }
                    }}
                />
            </div>
            <Responsive maxWidth={1024}>
                <MobilePanelFooter>
                    <BorderedButton
                        filled
                        caption="Save"
                        disabled={!isValid}
                        onClick={() => {
                            props.saveUser({
                                email: value,
                            });
                            props.closePanel();
                        }}
                    />
                </MobilePanelFooter>
            </Responsive>
            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
                <MobilePanelFooter>
                    <BorderedButton caption="Cancel" onClick={props.closePanel} />

                    <BorderedButton
                        filled
                        caption="Save"
                        disabled={!isValid}
                        onClick={() => {
                            props.saveUser({
                                email: value,
                            });
                            if (value) {
                                Analytics.trackEvent(
                                    Analytics.profile.profileEmail(MiscUtils.getAuthSource(authProvider))
                                );
                            }
                            props.closePanel();
                        }}
                    />
                </MobilePanelFooter>
            </Responsive>
        </div>
    );
});
