import React, { useEffect, useState } from 'react';

import styles from './BirthdayPanel.css';
import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { MonthsShort } from '../../../../constants/Months';
import { UserModel } from '../../../../models/User/UserModel';
import { DropdownDesktop } from '../../../../molecules/DropdownDesktop/DropdownDesktop';
import { ProfileProcessService } from '../../../../services/ProfileProcessService';

type Props = {
  saveUser: (user: any) => void;
  closePanel: () => void;
  user: UserModel;
};

export const BirthdayPanel = React.memo((props: Props) => {
  const d = props.user.birthday;
  const date = new Date(d);
  const months = MonthsShort;
  const [day, setDay] = useState<number>(d ? date.getDate() : -1);
  const [month, setMonth] = useState<string | -1>(d ? months[date.getMonth() - 1] : -1);
  const [year, setYear] = useState<number>(d ? date.getFullYear() : -1);

  useEffect(() => {
    if (!d) {
      return;
    }

    const d1 = new Date(d);

    setDay(d1.getDate());
    setMonth(months[d1.getMonth() - 1]);
    setYear(d1.getFullYear());
  }, [props.user]);

  function getNumberOfDaysInMonth(monthData: string, yearData: number): number {
    const months30 = [4, 6, 9, 11];
    const monthIndex = months.indexOf(monthData) + 1;
    let days: number;

    if (monthIndex === 2) {
      const leapYear = (yearData % 4 === 0 && yearData % 100 !== 0) || (yearData % 400 === 0);

      days = leapYear ? 29 : 28;
    } else if (months30.includes(monthIndex)) {
      days = 30;
    } else {
      days = 31;
    }

    return days;
  }

  const updateBirthday = async () => {
    if (year === -1 || month === -1 || day === -1) {
      return;
    }

    const initDate = new Date(year, months.indexOf(String(month)), day);
    const convertDate = new Date(initDate.getTime() - initDate.getTimezoneOffset() * 60000).toISOString();
    const updatedUser = {
      birthday: convertDate
    };

    props.saveUser({
      ...updatedUser,
      ...ProfileProcessService.getStepForUpdate(31)
    });
  };

  function setTheMonth(monthData: string) {
    setMonth(monthData);

    if (getNumberOfDaysInMonth(monthData, year) < day) {
      setDay(1);
    }
  }

  function setTheYear(yearData: number) {
    setYear(yearData);

    if (getNumberOfDaysInMonth(month as string, yearData) < day) {
      setDay(1);
    }
  }

  function setTheDay(e: number) {
    setDay(e);
  }

  const MonthMap = new Map<string, string>();

  for (let i = 0; i <= 11; i++) {
    MonthMap.set(String(months[i]), String(months[i]));
  }

  const DayMap = new Map<string, string>();

  for (let i = 1; i <= getNumberOfDaysInMonth(month as string, year); i++) {
    DayMap.set(String(i), String(i));
  }

  const currentYear = new Date().getFullYear();
  const YearMap = new Map<string, string>();

  for (let i = 0; i < 120; i++) {
    YearMap.set(String(currentYear - i), String(currentYear - i));
  }

  const saveButtonDisabled = year === -1 || month === -1 || day === -1;

  return (
    <>
      <div className={styles.content}>
        <DropdownDesktop
          id="month"
          placeholder={month !== -1 ? month.toString() : 'Month'}
          dropdownItems={MonthMap}
          onClick={(e) => setTheMonth(e)}
        />
        <DropdownDesktop
          id="day"
          placeholder={day !== -1 ? day.toString() : 'Day'}
          dropdownItems={DayMap}
          onClick={(e) => setTheDay(e)}
        />
        <DropdownDesktop
          id="year"
          placeholder={year !== -1 ? year.toString() : 'Year'}
          dropdownItems={YearMap}
          onClick={(e) => setTheYear(e)}
        />
      </div>
      <Responsive maxWidth={1024}>
        <MobilePanelFooter>
          <BorderedButton
            filled
            caption="Save"
            disabled={saveButtonDisabled}
            onClick={() => {
              void updateBirthday();
              props.closePanel();
            }}
          />
        </MobilePanelFooter>
      </Responsive>
      <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
        <MobilePanelFooter>
          <BorderedButton caption="Cancel" onClick={props.closePanel}/>

          <BorderedButton
            filled
            caption="Save"
            disabled={saveButtonDisabled}
            onClick={() => {
              void updateBirthday();
              props.closePanel();
            }}
          />
        </MobilePanelFooter>
      </Responsive>
    </>
  );
});
