import React from 'react';

import { BackgroundPicker } from './BackgroundPicker/BackgroundPicker';
import { FramesBlock } from './FramesBlock/FramesBlock';
import styles from './FramesPanel.css';

type FramesPanelProps = {
    isSignUp: boolean;
};

export const FramesPanel = React.memo(({ isSignUp }: FramesPanelProps) => {
    return (
        <div className={styles.container}>
            <BackgroundPicker />
            {!isSignUp && <FramesBlock />}
        </div>
    );
});
