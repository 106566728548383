import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import styles from './CountryPickerPanel.css';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { SearchSmallIcon } from '../../../../atoms/Icons/Styleguide/SearchSmallIcon';
import { Input } from '../../../../atoms/Input/Input';
import CountriesList from '../../../../constants/CountriesList';
import { UrlService } from '../../../../services/UrlService';

type CountryPickerPanelProps = {
    saveUser: (user: any) => void;
    closePanel: () => void;
};

type Country = {
    searchField: string;
    metaData: {
        countryCode: string;
    };
};

const countriesMap: Map<string, string> = new Map<string, string>();
CountriesList.forEach((item) => {
    countriesMap.set(item.id, item.name);
});

const countries: Country[] = Array.from(countriesMap.entries()).map(([countryCode]) => ({
    searchField: countriesMap.get(countryCode),
    metaData: { countryCode: countryCode },
}));

const filterCountries = (items: Country[], value: string): Country[] => {
    if (value.trim().length === 0) {
        return items;
    }
    const valueInLowerCase = value.toLowerCase();

    return items.filter((i) => {
        return i.searchField.toLowerCase().includes(valueInLowerCase);
    });
};

export const CountryPickerPanel = React.memo(({ saveUser, closePanel }: CountryPickerPanelProps) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation();
    const filteredItems: Country[] = filterCountries(countries, value);
    return (
        <>
            <div className={styles.content}>
                <div className={styles.input}>
                    <Input
                        value={value}
                        onValueChange={setValue}
                        placeholder={t('COUNTRY_PLACEHOLDER')}
                        name="search-country"
                    />
                    <button className={styles.searchIcon}>
                        <SearchSmallIcon />
                    </button>
                </div>
            </div>
            <div className={styles.list}>
                <ul>
                    {filteredItems.length > 0 ? (
                        filteredItems.map((item) => {
                            return (
                                <li
                                    className={styles.countryListItem}
                                    key={item.searchField}
                                    onClick={() => {
                                        saveUser({
                                            countryId: item.metaData.countryCode,
                                        });
                                        closePanel();
                                    }}
                                >
                                    <img
                                        alt="flag-icon"
                                        className={styles.countryFlag}
                                        src={UrlService.toCDNUrl(
                                            `/icons/flags-icons/${item.metaData.countryCode.toLowerCase()}.png`
                                        )}
                                    />
                                    <span className={styles.countryName}>{item.searchField}</span>
                                </li>
                            );
                        })
                    ) : (
                        <div className={styles.listWrapper}>
                            <div className={styles.noResultsWrapper}>
                                <I18nText className={styles.noResultsText} keyName="NOT_FOUND_COUNTRY" />
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        </>
    );
});
