import React, { useState } from 'react';

import classNames from 'classnames';

import styles from './UserNamePanel.css';
import { MiscUtils } from '../../../../../utils/MiscUtils';
import { ProfanityUtils } from '../../../../../utils/ProfanityUtils';
import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { BorderedButton } from '../../../../atoms/Buttons/BorderedButton/BorderedButton';
import { ButtonWhileTyping } from '../../../../atoms/Buttons/ButtonWhileTyping/ButtonWhileTyping';
import { Input } from '../../../../atoms/Input/Input';
import { Responsive } from '../../../../atoms/Layout/Responsive';
import { MobilePanelFooter } from '../../../../atoms/MobilePanelFooter/MobilePanelFooter';
import { ArkCssBreakpoints } from '../../../../constants/ArkCssBreakpoints';
import { UserModel } from '../../../../models/User/UserModel';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { LocalStorageService } from '../../../../services/LocalStorage';
import UserService from '../../../../services/UserService';

type Props = {
  saveUser: (user: { [x: string]: string; }) => void;
  closePanel: () => void;
  user: UserModel;
  field: string;
  hint: string;
  validation: string;
};

export const UserNamePanel = React.memo((props: Props) => {
  const [value, setValue] = useState<string>(props.user[props.field] || '');
  const [focus, setFocus] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isProfanity, setIsProfanity] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const authProvider = LocalStorageService.getItem('authProvider');
  const checkForProfanity = async () => {
    setIsProfanity(false);
    const isClean = await ProfanityUtils.checkWord(value);

    setIsProfanity(!isClean);
    return isClean;
  };
  const saveUser = async () => {
    await UserService.userUpdate({ [props.field]: value }).then((res) => {
      setLoading(false);

      if (res === 200) {
        if (props.field === 'name') {
          trackAnalytics();
        }

        props.saveUser({
          [props.field]: value
        });
        props.closePanel();
      } else {
        setErrorMessage(UserService.errorCodeToText(res));
      }

      return res;
    });
  };
  const trackAnalytics = () => {
    // simplifying this, since the other events were removed by the analytics
    return Analytics.trackEvent(Analytics.profile.publicProfileName(MiscUtils.getAuthSource(authProvider)));

  };

  return (
    <div className={styles.content}>
      <div
        className={classNames(styles.inputContainer, {
          [styles.inputContainerFocused]: focus
        })}
      >
        <Input
          value={value}
          onValueChange={(v) => {
            setValue(v);
            setErrorMessage('');
          }}
          onFocus={() => setFocus(true)}
          onBlur={() =>
            setTimeout(() => {
              setFocus(false);
            }, 200)
          }
          placeholder={props.hint}
          name={props.validation}
          isValid={isValid && !errorMessage}
          setIsValid={setIsValid}
          isProfanity={isProfanity}
          errorMessageOverride={errorMessage}
        />
        <ButtonWhileTyping
          isValid={isValid}
          focus={focus}
          onClick={async () => {
            setLoading(true);
            const isClean = await checkForProfanity();

            if (isClean && isValid && !errorMessage) {
              await saveUser();
            }

            setLoading(false);
          }}
        />
      </div>
      <Responsive maxWidth={1024}>
        <div className={classNames({ [styles.hide]: focus })}>
          <MobilePanelFooter>
            {!loading && (
              <BorderedButton
                filled
                caption="Save"
                disabled={!isValid && !!errorMessage}
                onClick={async () => {
                  setLoading(true);
                  const isClean = await checkForProfanity();

                  if (isClean) {
                    await saveUser();
                  }

                  setLoading(false);
                }}
              />
            )}
            {loading && <AppLoader/>}
          </MobilePanelFooter>
        </div>
      </Responsive>
      <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP}>
        <div className={classNames({ [styles.hide]: focus })}>
          <MobilePanelFooter>
            <BorderedButton caption="Cancel" onClick={props.closePanel}/>

            {!loading && (
              <BorderedButton
                filled
                caption="Save"
                disabled={!isValid && !!errorMessage}
                onClick={async () => {
                  setLoading(true);
                  const isClean = await checkForProfanity();

                  if (isClean) {
                    await saveUser();
                  }

                  setLoading(false);
                }}
              />
            )}
            {loading && <AppLoader/>}
          </MobilePanelFooter>
        </div>
      </Responsive>
    </div>
  );
});
