import React from 'react';

import classNames from 'classnames';

import { TickIcon } from '../../Icons/Styleguide/TickIcon';
import styles from './ButtonWhileTyping.css';

type ButtonWhileTyping = {
    onClick?: (e?: any) => void;
    onMouseDown?: (e?: any) => void;
    className?: string;
    focus: boolean;
    isValid: boolean;
};

export const ButtonWhileTyping = React.memo((props: ButtonWhileTyping) => {
    return (
        <div
            className={classNames(
                styles.saveButton,
                { [styles.hide]: !props.focus },
                { [styles.disabled]: !props.isValid }
            )}
            onClick={props.onClick}
            onMouseDown={props.onMouseDown}
        >
            <TickIcon className={styles.tickIcon} />
        </div>
    );
});
