import UserService from './UserService';
import { reduxStore } from '../store';
import { setUser } from '../store/ducks/user';
enum OnboardingSteps {
  Avatar = 11,
  Name = 12,
  FirstName = 21,
  Birthday = 22,
  EmailConsent = 31,
  Completed = 40,
}
const { store } = reduxStore;
const finalStepValue = OnboardingSteps.Completed;

export class ProfileProcessService {
  static getCurrentStep() {
    return this.isInProcess() ? store.getState().user.onboardingProgress || OnboardingSteps.Avatar : finalStepValue;
  }

  static finishProcess() {
    this.updateUserStep(finalStepValue);
  }

  static isInProcess() {
    return store.getState().user.onboardingProgress !== finalStepValue;
  }

  static initProgressBar() {
    const user = store.getState().user;

    if (user.onboardingProgress === 0) {
      if (!user.avatar) {
        this.updateUserStep(OnboardingSteps.Avatar );
      } else if (!user.name) {
        this.updateUserStep(OnboardingSteps.Name);
      } else if (!user.firstName) {
        this.updateUserStep(OnboardingSteps.FirstName);
      } else if (!user.birthday) {
        this.updateUserStep(OnboardingSteps.Birthday);
      } else if (!user.emailRetrievalConsent) {
        this.updateUserStep(OnboardingSteps.EmailConsent);
      } else {
        this.finishProcess();
      }
    }
  }

  static getStepForUpdate(step: number): Record<string, unknown> {
    const user = store.getState().user;

    if (!user) {
      return { onboardingProgress: OnboardingSteps.Avatar };
    }

    let updatedStep = user.onboardingProgress;

    if (user.onboardingProgress < step) {
      updatedStep = step;
    }

    return { onboardingProgress: updatedStep };
  }

  static updateUserStep(step: number) {
    if (this.isInProcess()) {
      const { user } = store.getState();

      UserService.userUpdate({ onboardingProgress: step }).then(() =>
        store.dispatch(
          setUser({
            ...user,
            ...{ onboardingProgress: step }
          })
        )
      );
    }
  }
}
