import React from 'react';

import classNames from 'classnames';

import styles from './MobilePanelFooter.css';

type MobilePanelFooterProps = {
    children: any;
    twoColumns?: boolean;
};

export const MobilePanelFooter = React.memo((props: MobilePanelFooterProps) => {
    const { children, twoColumns } = props;
    return (
        <div className={styles.footer}>
            <div className={classNames(styles.footer__content, { [styles.twoColumns]: twoColumns })}>{children}</div>
        </div>
    );
});
