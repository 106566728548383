import React from 'react';

import classNames from 'classnames';

import { Button } from '../../../../atoms/Buttons/Base/Base';
import { TickIcon } from '../../../../atoms/Icons/Styleguide/TickIcon';
import { UserModel } from '../../../../models/User/UserModel';
import styles from './GenderPanel.css';

type Props = {
    saveUser: (user: any) => void;
    closePanel: () => void;
    user: UserModel;
};

type Gender = {
    value: string;
    code: string;
};

const GenderMap = new Map<string, string>();
GenderMap.set('male', 'Male');
GenderMap.set('female', 'Female');
GenderMap.set('none', 'Prefer not to say');

export const GenderPanel = React.memo(({ saveUser, closePanel, user }: Props) => {
    return (
        <div className={styles.content}>
            <ul>
                {genders.map((item) => (
                    <li key={item.value}>
                        <Button
                            fullWidth
                            className={classNames(styles.genderListItem, {
                                [styles.active]: user.gender === item.code,
                            })}
                            onClick={() => {
                                saveUser({
                                    gender: item.code,
                                });
                                closePanel();
                            }}
                        >
                            {item.value}
                            {user.gender === item.code && <TickIcon className={styles.genderTickIcon} />}
                        </Button>
                    </li>
                ))}
            </ul>
        </div>
    );
});

const genders: Gender[] = Array.from(GenderMap.entries()).map(([genderCode]) => ({
    value: GenderMap.get(genderCode),
    code: genderCode,
}));
