import React, { FC, useEffect, useState } from 'react';

import { Avatar, AvatarTypes, BorderTypes } from '../../atoms/Avatar/Avatar';
import { AvatarDataType, AvatarImagesData } from '../../constants/AvatarImagesData';
import UserService from '../../services/UserService';
import styles from './AvatarsList.css';

export type AvatarsListProps = {
    selectedAvatar: string;
    isSignUp: boolean;
    onChange: (filename: string) => void;
};

export const AvatarsList: FC<AvatarsListProps> = React.memo(
    ({ selectedAvatar, isSignUp, onChange }: AvatarsListProps) => {
        const subscriber = UserService.isUserSubscriber();

        const [arrayOfAvatars, setArrayOfAvatars] = useState<AvatarDataType[]>([]);

        useEffect(() => {
            const AvatarImagesDataTemp = [...AvatarImagesData];

            const selectedIndex = AvatarImagesData.findIndex((item) => item.filename === selectedAvatar);
            if (selectedIndex !== -1) {
                AvatarImagesDataTemp.splice(selectedIndex, 1);
                AvatarImagesDataTemp.unshift(AvatarImagesData[selectedIndex]);
            }
            setArrayOfAvatars([...AvatarImagesDataTemp]);
        }, []);

        const DefaultAvatarsList = arrayOfAvatars.map((item, idx) => {
            if (!item?.premium && item?.filename) {
                return (
                    <Avatar
                        key={item.filename}
                        image={arrayOfAvatars[idx]?.filename}
                        selectable
                        selected={item?.filename === selectedAvatar}
                        onSelect={() => onChange(item?.filename)}
                        avatarList
                        size={AvatarTypes.MEDIUM_LIST_ITEM}
                        noFrame
                        focusable
                    />
                );
            }
            return null;
        });

        const PremiumAvatarsList = arrayOfAvatars.map((item, index) => {
            if (item?.premium) {
                return (
                    <Avatar
                        key={item.filename}
                        image={arrayOfAvatars[index].filename}
                        selectable={subscriber}
                        selected={item.filename === selectedAvatar}
                        onSelect={() => {
                            if (subscriber) {
                                onChange(item.filename);
                            }
                        }}
                        subscriber={subscriber}
                        avatarList
                        premium
                        border={BorderTypes.GOLD}
                        size={AvatarTypes.MEDIUM_LIST_ITEM}
                        noFrame
                        focusable
                    />
                );
            }
            return null;
        });

        return (
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    {!isSignUp ? (
                        <>
                            <div className={styles.defaultAvatars}>{DefaultAvatarsList}</div>
                            <div className={styles.premiumAvatars}>{PremiumAvatarsList}</div>
                        </>
                    ) : (
                        <div className={styles.signUpAvatars}>{DefaultAvatarsList}</div>
                    )}
                </div>
            </div>
        );
    }
);
