import React from 'react';

import classNames from 'classnames';

import { ArrowIcon } from '../../atoms/Icons/Styleguide/ArrowIcon';
import styles from './DropdownDesktop.css';

export type DropdownDesktopProps = {
    dropdownItems: Map<string, string>;
    placeholder: string;
    onClick: (e) => void;
    id: string;
    className?: string;
};

export const DropdownDesktop = React.memo(
    ({ className, dropdownItems, placeholder, onClick, id }: DropdownDesktopProps) => {
        const dropdownItemsArray = Array.from(dropdownItems);

        return (
            <div className={styles.dropdownContainer}>
                <label htmlFor={id} className={styles.screenReaderOnly}>
                    {placeholder}
                </label>
                <select
                    id={id}
                    className={classNames(styles[className], styles.dropdownButton)}
                    onChange={(event) => {
                        // We need to do '-1' because we have more select options than
                        // dropDownItemsArray items. That's because the first select option
                        // is actually just a placeholder.
                        const itemKey = dropdownItemsArray[event.target.options.selectedIndex - 1][0];
                        onClick(itemKey);
                    }}
                    defaultValue={placeholder}
                >
                    <option disabled hidden value={placeholder}>
                        {placeholder}
                    </option>
                    {dropdownItemsArray.map(([key, value]) => (
                        <option key={key}>{value}</option>
                    ))}
                </select>
                <ArrowIcon className={classNames(styles.dropdownArrow)} />
            </div>
        );
    }
);
